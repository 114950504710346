import { useContext } from "react";
import styled from "styled-components";
import moment from "moment";

import { Button } from "@atd/components";
import { Select } from "@atd/components/InputField";

import PlanContext from "./PlanContext";

const Amount = styled.span`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;
  line-height: 28px;
  font-weight: 700;
  font-size: 16px;
  color: #1a1f36;
  vertical-align: bottom;
  padding-left: 10px;
`;

function BreakUpCost({ amount, startDate, endDate, onAdd }) {
  return (
    <table className="table mb-0 wt-100 mgt1">
      <tbody>
        <tr>
          <td>
            <div className="tb-title bold mgb1">BREAK UP COST</div>
            <div className="title1">
              Since you are adding another monthly subscription to your existing
              account, your additional subscription will be charged only from{" "}
              {moment(startDate).format("MM-DD-YYYY")} to{" "}
              {moment(endDate).format("MM-DD-YYYY")} for this month.
            </div>
          </td>
          <td
            className="amt"
            style={{
              textAlign: "right",
              width: "auto",
              verticalAlign: "bottom",
              minWidth: 100,
            }}
          >
            <Amount>
              ${amount}&nbsp;<span style={{ color: "#949393" }}>/m</span>
            </Amount>
          </td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td style={{ verticalAlign: "bottom" }}>
            <div className="cont1 mt-3">
              <div className="Box-root Flex-flex fltrgt">
                <Button
                  variant="primary"
                  label="Add"
                  icon="plus"
                  onClick={onAdd}
                />
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function Subscriptions({ periods }) {
  const {
    numOfMonths,
    designers,
    selectedPlan,
    onChangePlan,
    onAddSummary,
    onChangeDesigners,
    onOpenSubscriptionModal,
  } = useContext(PlanContext);

  const {
    // total_offer_price,
    has_breakup,
    period_start,
    period_end,
    breakup_amount,
    offer_price,
  } = selectedPlan || {};

  return (
    <ul className="accordion-menu">
      <li className="open">
        <section
          className="content"
          style={{ border: "1px solid #E9F1F7", padding: 0 }}
        >
          <table className="table mb-0 wt-100">
            <thead>
              <tr>
                <th scope="col" className="border-0 tb-title bold">
                  SUBSCRIPTIONS{" "}
                  <Button
                    variant="link"
                    withPadding={false}
                    onClick={onOpenSubscriptionModal}
                  >
                    <div
                      aria-controls="menu3"
                      aria-haspopup="menu"
                      id="menu3-button"
                      aria-hidden="true"
                      className="SVGInline SVGInline--cleaned SVG Icon Icon--help Icon-color Icon-color--gray Box-root Flex-flex"
                    >
                      <svg
                        aria-controls="menu3"
                        aria-haspopup="menu"
                        id="menu3-button"
                        aria-hidden="true"
                        className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--help-svg Icon-color-svg Icon-color--gray-svg qus"
                        height={16}
                        width={16}
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zM7 8.089V8.5a1 1 0 0 0 1.926.377 3.456 3.456 0 0 0 1.549-.902 3.5 3.5 0 0 0-4.95-4.95A3.437 3.437 0 0 0 4.5 5.51a1.004 1.004 0 1 0 2.009-.02 1.43 1.43 0 0 1 .436-1.045 1.5 1.5 0 0 1 1.058-.437 1.492 1.492 0 0 1 0 2.983A1.004 1.004 0 0 0 7 8.09zM8 14a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
                          fillRule="evenodd"
                        />
                      </svg>
                    </div>
                  </Button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div
                    className="mt-2 w-100"
                    style={{ width: "auto !important;" }}
                  >
                    <div
                      className="range-slider mgt3"
                      style={{
                        "--min": 1,
                        "--max": 10,
                        "--step": 1,
                        "--value": designers,
                        "--text-value": `"${designers} Subscription${
                          designers > 1 ? "s" : ""
                        }"`,
                      }}
                    >
                      <input
                        type="range"
                        min={1}
                        max={10}
                        step={1}
                        defaultValue={1}
                        onChange={(e) => onChangeDesigners(e.target.value)}
                      />
                      <output />
                      <div className="range-slider__progress" />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table className="table mb-0 wt-100 mgt1">
            <tbody>
              <tr>
                <td className="bl-cont">
                  <div className="tb-title bold mgb1">BILLING PERIOD</div>
                  <Select
                    value={numOfMonths}
                    options={periods}
                    onChange={(e) => onChangePlan(e.target.value)}
                  />
                  {offer_price && (
                    <Amount>
                      ${Number(offer_price) * Number(designers)}{" "}
                      <span style={{ color: "#949393" }}>/m</span>
                    </Amount>
                  )}
                  {/* {total_offer_price && (
                    <Amount>
                      ${Number(total_offer_price) * Number(designers)}
                    </Amount>
                  )} */}
                </td>
                <td>
                  {!has_breakup && (
                    <div className="cont1 mt-3">
                      <div className="Box-root Flex-flex fltrgt">
                        <Button
                          variant="primary"
                          label="Add"
                          icon="plus"
                          onClick={onAddSummary}
                        />
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          {has_breakup && (
            <>
              <hr className="mgt2" />
              <BreakUpCost
                amount={breakup_amount}
                startDate={period_start}
                endDate={period_end}
                onAdd={onAddSummary}
              />
            </>
          )}
        </section>
      </li>
    </ul>
  );
}

export default Subscriptions;
